import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { SpinnerService } from '../shared/spinner/spinner.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../shared/common-services/common.service';
import { FileUploadService } from './file-upload-service';
import { requiredFileType, fileSizeValidator } from '../dynamic-form-components/services/question-control.service';
import { DashboardService } from '../dashboard/services/dashboard.service';

declare var _satellite: any;
declare var analyticsDataLayer: any;

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  providers: [CommonService, FileUploadService]
})
export class FileUploadComponent {
  form?: FormGroup;
  controls: any;
  allControlMetaData: any;
  metaData: any;
  file: File;
  @ViewChild('fileUploader') fileUploader: ElementRef



  constructor(private fb: FormBuilder, private spinnerService: SpinnerService, private toastr: ToastrService,
    private commonService: CommonService, private fileUploadService: FileUploadService, private dashboardService: DashboardService) {

  }

  ngOnInit() {
    this.createForm();
    this.getAllControlMetaData();
    this.trackOmniture('common/fileupload');
  }

  trackOmniture(pageName: string) {
    let loggedInUser;
    if (!loggedInUser)
      loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

    if (loggedInUser && loggedInUser.userName) {
      analyticsDataLayer.pageInfo.pageName = pageName;
      analyticsDataLayer.pageInfo.userID = loggedInUser.userName.split('@')[0];
      //_satellite.track("pageview");
    }
  }

  getAllControlMetaData() {
    this.spinnerService.showSpinner();
    this.dashboardService.getAllControlMetaData().subscribe((result: any) => {
      this.allControlMetaData = result.filter(c => c.isUpload == true);
      this.spinnerService.hideSpinner();
    });

  }
  getControlMetaData() {
    let controlName = this.form?.controls["controlName"].value;
    this.metaData = this.allControlMetaData.filter(c => c.controlName == controlName)[0];
    this.metaData.collectPhase.source.forEach(s => {
      this.form.addControl(s.sourceName, new FormControl(""));
    });
    if (this.metaData.collectPhase.source.length == 1) {
      this.form.controls['sourceName'].setValue(this.metaData.collectPhase.source[0].sourceName);
    }
  }
  handleUpload(event, name) {
    this.file = event.target.files[0];
    this.form?.controls[name].setValidators([fileSizeValidator(this.file)]);
    this.form?.controls[name].setValue("");
    this.form?.controls["fileName"].setValue(this.file.name);
  }
  createForm() {
    this.form = this.fb.group({
      controlName: new FormControl("", [Validators.required]),
      sourceName: new FormControl("", [Validators.required]),
      fileName: new FormControl("", [Validators.required, requiredFileType(['xlsx'])]),
    });
  }

  onControlChange(event: any) {
    this.form?.controls["fileName"].setValue("");
    this.form.controls['sourceName'].setValue("");
    this.file = null;
    this.getControlMetaData();
  }
  onSourceChange(sourceName: string) {
    this.form?.controls["fileName"].setValue("");
    this.form.controls['sourceName'].setValue(sourceName);
  }


  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }
  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }

  isFieldValid(field: string) {
    return !this.form.get(field).valid && this.form.get(field).touched && this.form.get(field).enabled;
  }
  onSubmit() {
    let sourceName = this.form?.controls["sourceName"].value;
    let isValid = this.form.get('controlName').valid && this.form.get('sourceName').valid && this.form?.controls["fileName"].valid && this.form.get(sourceName).valid;
    if (isValid) {
      this.spinnerService.showSpinner();
      let controlName = this.form?.controls["controlName"].value;
      //let file = this.form?.controls[sourceName].value;
      this.fileUploadService.uploadFiles(controlName, sourceName, this.file).subscribe(res => {
        if (res.isValid == false) {
          if (res.errorMessage && res.errorMessage.length == 0) {
            this.toastr.error('An error occured while uploading file');
          }
          else {
            this.toastr.error(res.errorMessage);
          }
        }
        else {
          this.reset();
          this.toastr.success("Uploaded Successfully");          
          let usernotificationtemplate = {
            notificationTemplateId: "7",
            controlName: controlName
          }
          this.fileUploadService.sentNotification(usernotificationtemplate).subscribe(msg => {
            
          },(err: any) => {
            this.toastr.error(err);            
          });
        }
        this.spinnerService.hideSpinner();

      }),
        (e => {
          this.spinnerService.hideSpinner();
          this.toastr.error(e);
        });
    }
    else
      this.validateAllFormFields(this.form);
  }
  reset() {
    this.fileUploader.nativeElement.value = "";
    this.form?.controls["fileName"].setValue("");
  }
}
