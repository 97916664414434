import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { CommonService } from '../shared/common-services/common.service';
import { ConfigService } from '../shared/common-services/config.service';
declare var _satellite: any;
declare var analyticsDataLayer: any;

@Injectable()
export class PersmissionRouteGuard implements CanActivate {

  constructor(private _sharedService: CommonService, private router:Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    {
    
      var securityActions = JSON.parse(localStorage.getItem("SecurityAction"));
      if (!securityActions) {
        this.router.navigate(['/login'])
      }
     
      sessionStorage.setItem("navigateurl", JSON.stringify(route.url))
      if (route.url == []) {
        //this.trackOmniture('home');
        return this._sharedService.hasAccess('general', 'dashboard/read');
       
      }
      else if (route.url[0].path.indexOf('UserManagement') > -1) {
        //this.trackOmniture('UserManagement'); 
        return this._sharedService.hasAccess('general', 'um/user/readall');
      }
      else if (route.url[0].path.indexOf('UserRoleManagement') > -1) {
        //this.trackOmniture('UserRoleManagement');
        return this._sharedService.hasAccess('general', 'role/action/readall');
      }
      else if (route.url[0].path.indexOf('ManageBannedEntity') > -1) {
        //this.trackOmniture('ManageBannedEntity');
        return this._sharedService.hasAccess('general', 'banned/entity/edit');
      }
      else if (route.url[0].path.indexOf('ManageQuestionnaire') > -1) {
        //this.trackOmniture('ManageQuestionnaire');
        return this._sharedService.hasAccess('general', 'ma/Attestation/read');
      }
      else if (route.url[0].path.indexOf('NotificationDashboard') > -1) {
        //this.trackOmniture('NotificationDashboard');
        return this._sharedService.hasAccess('general', 'mg/notification/template');
      }
      else if (route.url[0].path.indexOf('managecontrolmetadata') > -1) {
        //this.trackOmniture('NotificationDashboard');
        return this._sharedService.hasAccess('general', 'mg/controlmetadata');
      }
      else if (route.url[0].path.indexOf('home') > -1) {
       /* this.trackOmniture('home');*/
        return this._sharedService.hasAccess('general', 'dashboard/read');
      }             
      else if (route.url[0].path.indexOf('attestation') > -1) {
        //this.trackOmniture('attestation/' + route.url[1].path + '/' + route.url[2].path);
        return this._sharedService.hasAccess(route.url[1].path, 'view');
      }
      else if (route.url[0].path.indexOf('common') > -1 && route.url[1].path.indexOf('fileupload') > -1) {
        //this.trackOmniture('common/fileupload');
        return (this._sharedService.hasAccess('Procurement', 'submit') || this._sharedService.hasAccess('VendorCertification', 'view'));
      }
      else if (route.url[0].path.indexOf('dashboarddetails') > -1) {
      //  this.trackOmniture('dashboarddetails/' + route.url[1].path);
        return this._sharedService.hasAccess(route.url[1].path, 'view');
      }      
     
      return this.router.navigate(['/unauthorized']);
    }      
  }  


  trackOmniture(pageName: string) {
    let loggedInUser;
    if (!this._sharedService.loggedInUser)
      loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    else {
      loggedInUser = this._sharedService.loggedInUser;
    }
    if (loggedInUser && loggedInUser.userName) {
      analyticsDataLayer.pageInfo.pageName = pageName;
      analyticsDataLayer.pageInfo.userID = loggedInUser.userName.split('@')[0];
      //_satellite.track("pageview");
    }
  }
}
