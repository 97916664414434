
import { UserManagementService } from "./services/user-management.service";
import {
  Component, Output, EventEmitter, ViewChild, AfterViewInit
 } from '@angular/core';
import { CommonService } from '../shared/common-services/common.service';
import { ActivatedRoute } from '@angular/router';
import { SpinnerService } from '../shared/spinner/spinner.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { CreateUserComponent } from '../create-user/create-user.component';

export interface UserData {
  userName: string;
  role: string;
  updatedBy: string;
  updatedOn: string;
  alias: string
}

declare var _satellite: any;
declare var analyticsDataLayer: any;

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss'],
  exportAs: "appPagination"
})
export class UserManagementComponent implements AfterViewInit {
  queueNotification: any;
  originalUserList: Array<any> = [];
  items = [];
  pageOfItems: Array<any>;
  user: any;
  userList: UserData[];
  alias: any;
  persons: Array<any> = [];
  userListSelected: Array<any>=[];
  public window: any = window;
  @Output() reloadData: EventEmitter<any> = new EventEmitter();
  displayedColumns: string[] = ['userName', 'role','updatedBy','updatedOn','Action'];
  dataSource: MatTableDataSource<UserData>;
  UsersDataSource: MatTableDataSource<UserData>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(public dialog: MatDialog, private userManagementService: UserManagementService,  private spinnerService: SpinnerService, private route: ActivatedRoute, private toastr: ToastrService, private commonService: CommonService) {
    this.commonService.getRoles();
    this.commonService.getControls();
    this.getAllUsers();

  }

  ngOnInit(): void {
    this.UsersDataSource = new MatTableDataSource(this.userList);
    this.trackOmniture('UserManagement');
  }

  trackOmniture(pageName: string) {
    let loggedInUser;
    if (!loggedInUser)
      loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

    if (loggedInUser && loggedInUser.userName) {
      analyticsDataLayer.pageInfo.pageName = pageName;
      analyticsDataLayer.pageInfo.userID = loggedInUser.userName.split('@')[0];
      //_satellite.track("pageview");
    }
  }

    openDialog(user: any): void {
      const dialogRef = this.dialog.open(CreateUserComponent, {
        data: { alias: user, userList: this.userList, originalList: this.originalUserList }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result != null || result != undefined) {
          this.userList = result.data[0];
          this.originalUserList = result.data[1];
        }
        this.UsersDataSource = new MatTableDataSource(this.userList);
        setTimeout(() => this.UsersDataSource.paginator = this.paginator);
        setTimeout(() => this.UsersDataSource.sort = this.sort);
      });
    }

  ngAfterViewInit() {
    setTimeout(() => this.UsersDataSource.paginator = this.paginator);
    this.UsersDataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.UsersDataSource.filter = filterValue.trim().toLowerCase();

    if (this.UsersDataSource.paginator) {
      this.UsersDataSource.paginator.firstPage();
    }
  }
  
  setupFilter(column: string) {
    this.UsersDataSource.filterPredicate = (d: any, filter: string) => {
      const textToSearch = d[column] && d[column].toLowerCase() || '';
      return textToSearch.indexOf(filter) !== -1;
    };
  }

  getFormattedDate(date: Date) {
    var dd = date.getDate();
    var mm = date.getMonth()+1;
    var yyyy = date.getFullYear();
    return mm + "/" + dd + "/" + yyyy;
  }
   
  
  getAllUsers() {
    this.userList = [];
    this.spinnerService.showSpinner();
    this.userManagementService.getAllUsers().subscribe((result: any) => {
      if (result != null) {
        this.originalUserList = result;
        if (result.length > 0) {
          for (var i = 0; i < result.length; i++) {
            var UserName = result[i].userAttributes.fullName;
            var roles = result[i].roles;
            var alias = result[i].userAttributes.alias;
            var roleName: string = '';
            var updatedBy: string;
            var updatedOn: any;
            roles.forEach((element: {
              roleName: any;
              isActive: any;
              updatedBy: any;
              updatedOn: any;
            }) => {          
              updatedBy = element.updatedBy;
              updatedOn = element.updatedOn;
              roleName = roleName + ', ' + element.roleName;
            });
            roleName = roleName.substring(2);
            var newDate = this.getFormattedDate(new Date(updatedOn));
            if (roleName != '' && result[i].userAttributes.isActive == true) {
              this.userList.push({ userName: UserName, role: roleName, updatedBy: updatedBy, updatedOn: newDate, alias: alias });
            }
            this.UsersDataSource = new MatTableDataSource(this.userList);
            setTimeout(() => this.UsersDataSource.paginator = this.paginator);
            setTimeout(() => this.UsersDataSource.sort = this.sort);
            roleName = '';
          }
        }
      } else {
        this.userList = [];
      }
      this.spinnerService.hideSpinner();     
    });
  }  

  deleteUserRole(userAlias: any, role: string) {
    if (this.window.confirm("Are you sure you want to Delete the User")) {
    var userToDelete = this.originalUserList.filter(item => item.userAttributes.alias == userAlias);
      userToDelete[0].userAttributes.isActive = false;
      this.spinnerService.showSpinner();
      this.userManagementService.addUser(userToDelete[0]).subscribe(alias => {
        this.getAllUsers();
      //var index = this.userList.findIndex(a => a.alias == userAlias);
      //this.userList.splice(index);
      //this.UsersDataSource = new MatTableDataSource(this.userList);
      //setTimeout(() => this.UsersDataSource.paginator = this.paginator);
      //setTimeout(() => this.UsersDataSource.sort = this.sort);      
        this.toastr.success('User Deleted Successfully');
        var createdBy = JSON.parse(localStorage.getItem("loggedInUser"));
        this.queueNotification = { "notificationTemplateId": "9", "controlName": "", "createdBy": createdBy.name, "user": userToDelete[0].userAttributes.alias, "userName": userToDelete[0].userAttributes.firstName };
        this.userManagementService.addQueueNotification(this.queueNotification).subscribe(data => {
        });
      //this.spinnerService.hideSpinner();
    });      
    }
  }

  hasAccess(groupName: string, securityAction: string): boolean {
    return true;
  }

}



