import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService, BroadcastService } from '@azure/msal-angular';
import { Subscription } from 'rxjs';
import { CommonService } from './shared/common-services/common.service';
import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Renderer2, Inject } from '@angular/core';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
    nonceValue: string;

  constructor(private msalService: MsalService, private broadcastService: BroadcastService, private renderer: Renderer2, @Inject(DOCUMENT) private document: Document) {
      
  }
   
  ngOnInit() {   
  }
  ngAfterViewInit():void {
    console.log("App component nonce href " + window.location.href);
    fetch(window.location.origin + "/assets/config.json").then((res) => {
      this.nonceValue = res.headers.get("x-csp-nonce");
      if (this.nonceValue) {
        console.log("App component nonce :" + this.nonceValue);
        this.document.querySelectorAll('[nonce="nonce-value"]').forEach((e) => {
          e.setAttribute("nonce", this.nonceValue);
        });
      } else {
        console.log("App component nonce not found in header response");
      }
    });
    const scripts = this.document.querySelectorAll('script');
    scripts.forEach(script => {
      if (!script.hasAttribute('nonce')) {
        console.log("App component nonce scripts");
        this.nonceValue = this.nonceValue != null ? this.nonceValue : Math.random().toString(36).slice(2);
        // Add the nonce attribute
        this.renderer.setAttribute(script, 'nonce', this.nonceValue); // Replace with your actual nonce value
      }
    });

    const styleTag = this.document.querySelectorAll('style');
    styleTag.forEach(script => {
      console.log("App component nonce scripts");
      this.nonceValue = this.nonceValue != null ? this.nonceValue : Math.random().toString(36).slice(2);
      // Add the nonce attribute
      this.renderer.setAttribute(script, 'nonce', this.nonceValue); // Replace with your actual nonce value

    });
  }
   ngOnDestroy() {
    this.broadcastService.getMSALSubject().next(1);
    this.msalService.logout();
    localStorage.clear();
  }

}





