import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { CreateRoleComponent } from '../create-role/create-role.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { UserManagementService } from '../user-management/services/user-management.service';
import { CommonService } from '../shared/common-services/common.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { SpinnerService } from '../shared/spinner/spinner.service';
import { MasterData } from '../dynamic-form-components/models/question-base';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox'
import { forkJoin } from 'rxjs';

declare var _satellite: any;
declare var analyticsDataLayer: any;

export class RoleAccessData {
  actions: string;
  Administrator: boolean;
  Contributor: boolean;
  ViewOnly: boolean;
}

@Component({
  selector: 'app-user-role-management',
  templateUrl: './user-role-management.component.html',
  styleUrls: ['./user-role-management.component.scss']
})

export class UserRoleManagementComponent implements OnInit {
  queueNotification: any;
  roleAccessData: RoleAccessData = new RoleAccessData();
  userRoleList: RoleAccessData[];
  dataSources: MatTableDataSource<RoleAccessData>;
  UsersDataSource: MatTableDataSource<RoleAccessData>;
  securityActions: Array<MasterData> = [];
  roles: Array<MasterData> = [];
  definedColumns: Array<string> = [];
  roleActions: any;
  originalResult: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  loggedInUser: any;

  constructor(
    public dialog: MatDialog, private userManagementService: UserManagementService, private spinnerService: SpinnerService, private route: ActivatedRoute, private toastr: ToastrService, private commonService: CommonService) {
    this.loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
  }

  ngOnInit(): void {
    this.getActions();
    this.trackOmniture('UserRoleManagement');
  }

  trackOmniture(pageName: string) {
    let loggedInUser;
    if (!loggedInUser)
      loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

    if (loggedInUser && loggedInUser.userName) {
      analyticsDataLayer.pageInfo.pageName = pageName;
      analyticsDataLayer.pageInfo.userID = loggedInUser.userName.split('@')[0];
      //_satellite.track("pageview");
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.UsersDataSource.filter = filterValue.trim().toLowerCase();

    if (this.UsersDataSource.paginator) {
      this.UsersDataSource.paginator.firstPage();
    }
  }

  setupFilter(column: string) {
    this.UsersDataSource.filterPredicate = (d: any, filter: string) => {
      const textToSearch = d[column] && d[column].toLowerCase() || '';
      return textToSearch.indexOf(filter) !== -1;
    };
  }

  openDialog(user: any): void {
    const dialogRef = this.dialog.open(CreateRoleComponent, {
      data: { alias: user, userList: this.userRoleList }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null || result != undefined) {
        this.userRoleList = result.data;
      }
      this.UsersDataSource = new MatTableDataSource(this.userRoleList);
    });
  }

  getActions() {
    this.roleAccessData = new RoleAccessData();
    this.userRoleList = [];
    this.spinnerService.showSpinner();
    forkJoin(
      this.userManagementService.getAction('SecurityAction'),
      this.userManagementService.getAction('Role'),
      this.userManagementService.getActionByRole(),
    ).subscribe(([action, role, roleAction]) => {
      this.originalResult = roleAction;
      this.roleActions = roleAction;
      this.securityActions = action;
      this.roles = role;
      this.definedColumns = [];
      this.definedColumns.push('actions');
      this.roles.forEach((element: {
        id: string;
      }) => {
        this.definedColumns.push(element.id);
      });
      for (var j = 0; j < this.securityActions.length; j++) {
        this.roleAccessData = new RoleAccessData();
        var adminSecurity = this.roleActions.filter(x => x.roleName == "Administrator") != undefined ? this.roleActions.filter(x => x.roleName == "Administrator")[0].securityActions : null;
        var isAdministratorAccess = adminSecurity != undefined ? adminSecurity.filter(y => y.groupType == "common") : null;
        this.roleAccessData.Administrator = isAdministratorAccess != null ? isAdministratorAccess.filter(z => z.actionName == this.securityActions[j].id).length > 0 : false;

        var contributorSecurity = this.roleActions.filter(x => x.roleName == "Contributor") != undefined ? this.roleActions.filter(x => x.roleName == "Contributor")[0].securityActions : null;
        var isContributorAccess = contributorSecurity != undefined ? contributorSecurity.filter(y => y.groupType == "common") : null;
        this.roleAccessData.Contributor = isContributorAccess != null ? isContributorAccess.filter(z => z.actionName == this.securityActions[j].id).length > 0 : false;

        var viewonlySecurity = this.roleActions.filter(x => x.roleName == "View Only") != undefined ? this.roleActions.filter(x => x.roleName == "View Only")[0].securityActions : null;
        var isViewOnlyAccess = viewonlySecurity != undefined ? viewonlySecurity.filter(y => y.groupType == "common") : null;
        this.roleAccessData.ViewOnly = isViewOnlyAccess != null ? isViewOnlyAccess.filter(z => z.actionName == this.securityActions[j].id).length > 0 : false;

        var securityAction = this.securityActions[j].name;
        this.roleAccessData.actions = securityAction;
        this.userRoleList.push(this.roleAccessData);
      }
      this.UsersDataSource = new MatTableDataSource(this.userRoleList);
      setTimeout(() => this.UsersDataSource.paginator = this.paginator);
      setTimeout(() => this.UsersDataSource.sort = this.sort);
      this.spinnerService.hideSpinner();
    });
  }

  selectionChanged(actions: string, roleName: string, e: any) {
    if (e.target.checked) {
      var roleAction = this.originalResult.filter(x => x.roleName == roleName);
      var securityActions = roleAction != undefined ? roleAction[0].securityActions : null;
      securityActions.push({ actionName: this.securityActions.filter(x => x.name == actions)[0].id, allowedResources: [], groupType: 'common' });
      this.originalResult.filter(x => x.roleName == roleName)[0].securityActions = securityActions;
    }
    else {
      var roleAction = this.originalResult.filter(x => x.roleName == roleName);
      var securityActions = roleAction != undefined ? roleAction[0].securityActions : null;
      securityActions.splice(securityActions.findIndex(x => x.actionName == this.securityActions.filter(x => x.name == actions)[0].id), 1);
      this.originalResult.filter(x => x.roleName == roleName)[0].securityActions = securityActions;
    }

  }

  saveSecurityActionByRole() {
    this.spinnerService.showSpinner();
    this.originalResult.forEach((element: any) => {
      this.userManagementService.addActionByRole({ roleName: element.roleName, securityActions: element.securityActions }).subscribe(result => {
       this.spinnerService.hideSpinner();
      },
        error => {
          this.toastr.error("An Error has occured. Please try again later.");
          this.spinnerService.hideSpinner();
        });
    });
    this.queueNotification = { "notificationTemplateId": "6", "controlName": "", "createdBy": this.loggedInUser.name };
    this.userManagementService.addQueueNotification(this.queueNotification).subscribe(data => {
    });
    this.toastr.success("Actions saved successfully");
  }


  cancelSecurityActionByRole() {
    this.getActions();
  }

}
