
import {
  Component, Output, EventEmitter, ViewChild, AfterViewInit, OnInit, NgModule
} from '@angular/core';
import { CommonService } from '../shared/common-services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerService } from '../shared/spinner/spinner.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { CreateUserComponent } from '../create-user/create-user.component';
import { notificationdata } from '../emailtemplate/models/notification-data';
import { EmailtemplateService } from '../emailtemplate/services/emailtemplate.service';
import { MatPaginatorModule } from '@angular/material/paginator';

declare var _satellite: any;
declare var analyticsDataLayer: any;
@Component({
  selector: 'app-notification-dashboard',
  templateUrl: './notification-dashboard.component.html',
  styleUrls: ['./notification-dashboard.component.scss'],
  exportAs: "appPagination"
})
export class NotificationDashboardComponent implements  AfterViewInit 
 {
  templateList: notificationdata[];
  @Output() reloadData: EventEmitter<any> = new EventEmitter();
  displayedColumns: string[] = ['notificationTemplateId', 'to', 'cc', 'subject', 'Edit'];
  dataSource: MatTableDataSource<notificationdata>;
  TemplateDataSource: MatTableDataSource<notificationdata>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(private emailtemplateService: EmailtemplateService, private spinnerService: SpinnerService,  private router: Router) {
   this.getAllNotificationTemplates();
  }
  ngOnInit(): void {
    this.TemplateDataSource = new MatTableDataSource(this.templateList);
    this.trackOmniture('NotificationDashboard');
  }

  trackOmniture(pageName: string) {   
    let loggedInUser;
    if (!loggedInUser)
      loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
   
    if (loggedInUser && loggedInUser.userName) {
      analyticsDataLayer.pageInfo.pageName = pageName;
      analyticsDataLayer.pageInfo.userID = loggedInUser.userName.split('@')[0];
      //_satellite.track("pageview");
    }
  }

  editTemplate(id: any) {
    this.router.navigate(['/emailtemplate', id]);
  }

  createNotificationTemplate() {
    this.router.navigate(['/emailtemplate']);
  }

  ngAfterViewInit() {
    setTimeout(() => this.TemplateDataSource.paginator = this.paginator);
    this.TemplateDataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.TemplateDataSource.filter = filterValue.trim().toLowerCase();

    if (this.TemplateDataSource.paginator) {
      this.TemplateDataSource.paginator.firstPage();
    }
  }

  setupFilter(column: string) {
    this.TemplateDataSource.filterPredicate = (d: any, filter: string) => {
      const textToSearch = d[column] && d[column].toLowerCase() || '';
      return textToSearch.indexOf(filter) !== -1;
    };
  }

  getAllNotificationTemplates() {
    this.templateList = [];
    this.spinnerService.showSpinner();
    this.emailtemplateService.getAllEmailTemplate().subscribe((result: notificationdata[]) => {
      if (result != null) {
        if (result.length > 0) {
          for (var i = 0; i < result.length; i++) {
        
              this.templateList.push({
                notificationTemplateId: result[i].notificationTemplateId,
                to: result[i].to,
                cc: result[i].cc,
                subject: result[i].subject,
                body: result[i].body,
                notificationType: result[i].notificationType,
                isActive: result[i].isActive,
                frequency: result[i].frequency,
                frequencyType: result[i].frequencyType,
                frequencyBeforeOrAfter: result[i].frequencyBeforeOrAfter,
                columnValue: result[i].columnValue
              });
            

            this.TemplateDataSource = new MatTableDataSource(this.templateList);
            setTimeout(() => this.TemplateDataSource.paginator = this.paginator);
            setTimeout(() => this.TemplateDataSource.sort = this.sort);
          }
        }
      } else {
        this.templateList = [];
      }
      this.spinnerService.hideSpinner();
    });
  }
   
}




