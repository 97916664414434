import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { AddBannedEntityComponent } from '../add-banned-entity/add-banned-entity.component';
import { ManageEntityService } from './services/manage-entity.service';
import { ToastrService } from 'ngx-toastr';
/*import { MatSort } from '@angular/material/sort';*/
import { SpinnerService } from '../shared/spinner/spinner.service';
import { UploadBannedEntityComponent } from './upload-banned-entity.component';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MasterData } from '../dynamic-form-components/models/question-base';
import { CommonService } from '../shared/common-services/common.service';

declare var _satellite: any;
declare var analyticsDataLayer: any;

export interface PeriodicElements {
  id: string;
  name: string;
  tags: Array<Tags>
  updatedOn: string;
  updatedBy: string;
}
export class Tags {
  value: string;
}
const ELEMENT_DATAS: PeriodicElements[] = [];

@Component({
  selector: 'app-manage-entity',
  templateUrl: './manage-entity.component.html',
  styleUrls: ['./manage-entity.component.scss']
})
export class ManageEntityComponent implements OnInit {
  originalManageList: Array<any> = [];
  public ELEMENT: PeriodicElements[] = [];
  selectedEntityType: string;
  public ErrMsg: string;
  displayedColumns: any[] = ['name', 'tags', 'updatedOn', 'updatedBy', 'action'];
  dataSource = ELEMENT_DATAS;

  entityList: PeriodicElements[];
  dataSources: MatTableDataSource<PeriodicElements>;
  UsersDataSource: MatTableDataSource<PeriodicElements>;
  form?: FormGroup;
  parentEntities: MasterData[];
  /* @ViewChild(MatPaginator) paginator: MatPaginator;*/
  /*@ViewChild(MatSort) sort: MatSort;*/

  constructor(private fb: FormBuilder, public dialog: MatDialog, private manageservice: ManageEntityService, private spinnerService: SpinnerService, private toastr: ToastrService, private commonService: CommonService,) {
  }

  ngOnInit(): void {

    //this.getParentEntities();
    this.form = this.fb.group({
      type: new FormControl(""),
      parentEntity: new FormControl(""),
    });
    this.form.controls['type'].setValue('Parent');
    this.getParentEntities();
    this.trackOmniture('ManageBannedEntity');
  }
  trackOmniture(pageName: string) {
    let loggedInUser;
    if (!loggedInUser)
      loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

    if (loggedInUser && loggedInUser.userName) {
      analyticsDataLayer.pageInfo.pageName = pageName;
      analyticsDataLayer.pageInfo.userID = loggedInUser.userName.split('@')[0];
      //_satellite.track("pageview");
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.UsersDataSource.filter = filterValue.trim().toLowerCase();
  }

  setupFilter(column: string) {
    this.UsersDataSource.filterPredicate = (d: any, filter: string) => {
      const textToSearch = d[column] && d[column].toLowerCase() || '';
      return textToSearch.indexOf(filter) !== -1;
    };
  }
  showAddButton() {
    if (this.form.controls['type'].value == 'Parent')
      return true;
    else if (this.form.controls['type'].value == 'Subsidiary' && this.form.controls['parentEntity'].value)
      return true;
    else
      return false;

  }

  showuploadButton() {
    if (this.form.controls['type'].value == 'Subsidiary')
      return true;
    else if (this.form.controls['type'].value == 'Parent' && this.form.controls['parentEntity'].value)
      return true;
    else
      return false;

  }


  uploadDialog(): void {

    const dialogRef = this.dialog.open(UploadBannedEntityComponent, {
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.data.errorMsg!=undefined && result.data.errorMsg != "") {
        this.ErrMsg = result.data.errorMsg;
         let element: HTMLElement = document.getElementById('idOpenModelButton') as HTMLElement;
         element.click();     
      }
      if (this.form.controls['type'].value == 'Parent')
        this.getParentEntities();
      else if (this.form.controls['parentEntity'].value != "")
        this.getSubsidaries();
    });
  }
  openDialog(entity): void {
    debugger;
    const dialogRef = this.dialog.open(AddBannedEntityComponent, {
      /* data:  entity */
      data: { entityList: this.entityList, entity: entity, type: this.form.controls['type'].value, parentTypeId: this.form.controls['parentEntity'].value }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (this.form.controls['type'].value == 'Parent')
        this.getParentEntities();
      else
        this.getSubsidaries();
    });
  }

  deleteEntityById(id) {
    let type = "";
    let parentTypeId = null;
    if (this.form.controls['type'].value == "Parent")
      type = "ParentProhibitedEntity";
    else {
      type = "ProhibitedEntities";
      parentTypeId = this.form.controls['parentEntity'].value;
    }

    if (confirm("Are you sure you want to Delete the Entity")) {
      this.spinnerService.showSpinner();
      this.manageservice.deleteData(type, id, parentTypeId).subscribe(id => {
        if (this.form.controls['type'].value == 'Parent')
          this.getParentEntities();
        else
          this.getSubsidaries();
        this.toastr.success(this.selectedEntityType+' Entity Deleted Successfully');
        this.spinnerService.hideSpinner();
      });
    }
  }

  getFormattedDate(date: Date) {
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    var yyyy = date.getFullYear();
    return mm + "/" + dd + "/" + yyyy;
  }
  resetGrid() {
    this.entityList = [];
    this.UsersDataSource = new MatTableDataSource(null);
  }
  onParentChange() {
    this.form.controls['parentEntity'].setValue("");
    this.getParentEntities();
    this.resetGrid();
  }

  getParentEntities() {
    this.spinnerService.showSpinner();
    let type = "ParentProhibitedEntity";
    this.selectedEntityType=this.form.controls['type'].value;
    this.manageservice.getData(type).subscribe(result => {
      this.parentEntities = result;
      if (this.form.controls['type'].value == 'Parent')
        this.bindGrid(result);
      this.spinnerService.hideSpinner();
    });
  }

  getSubsidaries() {
    this.spinnerService.showSpinner();
    this.resetGrid();
    let type = "ProhibitedEntities";
    let parentEntityId = this.form.controls['parentEntity'].value;
    this.manageservice.getByParentEntityId(type, parentEntityId).subscribe(result => {
      this.bindGrid(result);
      this.spinnerService.hideSpinner();
    });
  }

  bindGrid(data: MasterData[]) {
    this.entityList = [];
    this.originalManageList = data;
    for (let i = 0; i < data.length; i++) {
      var nameData = data[i].name;
      var tagsName = [];
      if (data[i].tags.length > 0) {
        for (let j = 0; j < data[i].tags.length; j++)
          if (data[i].tags[j].value)
            tagsName.push(data[i].tags[j].value);
      }
      var idData = data[i].id;
      var typeName = data[i].type;
      var updatedBy = data[i].updatedBy;
      var updatedOn = data[i].updatedOn;
      var newDate = this.getFormattedDate(new Date(updatedOn));
      if (typeName != '') {
        this.entityList.push({ id: idData, name: nameData, tags: tagsName, updatedBy: updatedBy, updatedOn: newDate });
      }
    };
    this.UsersDataSource = new MatTableDataSource(this.entityList);
  }

  hasAccess(groupName: string, securityAction: string): boolean {
    return this.commonService.hasAccess(groupName, securityAction);
  }

  downloadBandEntity() {
    this.spinnerService.showSpinner();
    this.selectedEntityType = this.form.controls['type'].value;
    //let type = "ProhibittedEntity";
    this.manageservice.downloadMasterData(this.selectedEntityType).subscribe((data: any) => {
      var blob = this.commonService.convertBase64ToBlobData(data, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"); //new Blob([byteArray], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
      var url = window.URL.createObjectURL(blob);

      var link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", this.selectedEntityType=="Parent"?"ParentEntityRecords.xlsx" : "BandEntities.xlsx");
      //link.setAttribute("download", "BandEntities.xlsx");
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link)
      this.spinnerService.hideSpinner();
    },
      (err: any) => {
        this.toastr.error(err);
        this.spinnerService.hideSpinner();
      }
    );
  }
}













